.navbar {
  border-bottom: $base-border;
}

.navbar-nav {
  @extend %flex-vertical-center;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-assistant-searchbar {
  border: $base-border;
  background: #fafcfd;
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
  font-weight: 600;
  color: #999;
  width: 20%;
  min-width: 60px;
  transition: 0.25s ease-in-out;

  &:hover {
    color: #666;
  }
}

.nav-item {
  font-size: 15px;
  min-width: 50px;

  .nav-link {
    color: #999 !important;
    transition: 0.25s ease-in-out;

    &:hover {
      background: #0000000c;
      border-radius: 5px;
      .nav-link {
        color: #666 !important;
      }
    }

    &.active {
      color: var(--orange) !important;
      font-weight: 600;
    }
  }
}

.navbar-user-avatar {
  @include size(40px);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

%navbar-links {
  font-weight: 600;
  opacity: 0.8;
  transition: 0.25s ease-in-out;
  color: #333;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.navbar-user-menu {
  .navbar-user-menu--link {
    @extend %navbar-links;
    @extend %flex-vertical-center;
    min-width: 150px;

    i {
      margin-left: auto;
      font-size: 1.4rem;
    }
  }
}

.navbar-dropdown-link-title {
  font-size: 13px;
}

.navbar-shortcut-menu {
  max-width: 230px;

  img {
    max-height: 40px;
    max-width: 48px;
  }

  i {
    font-size: 26px;
  }

  .img-container {
    @extend %flex-all-center;
    height: 40px;
    margin-bottom: 0.6rem;
  }

  a {
    @extend %navbar-links;
  }
}

.navbar-notifications-menu--wrapper {
  max-width: 350px;
}

.navbar-notifications-menu--nlist {
  li:not(:last-child) {
    border-bottom: $base-border;
  }
}

.navbar-main-links {
  @include respond-below(md) {
    align-items: flex-start;

    .nav-item {
      margin-bottom: 1.5rem;

      .nav-link {
        padding: 0;
      }
    }
  }
}

.collapsible-navbar-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80vw;
  width: 280px;
  transition: 0.2s ease-in-out;
  background: white;
  z-index: 1050;
  padding: 1.4rem;
  flex-direction: column;
  transform: translateX(280px);

  &.collapsible-navbar-menu--expanded {
    transform: translateX(0);
    box-shadow: -10px 0px 14px 0px rgba(0, 0, 0, 0.06);
  }
}
