.assistant-answer {
  color: #333;
  font-size: map-get($text-sizes, 'xl');
  font-weight: 600;
}

.assistant--main-result-row {
  flex-wrap: nowrap;
  padding-bottom: 1rem;
}

.overview-grid {
  // margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  @include respond-below(lg) {
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
  }
}

.assistant-searchbar {
  @extend %primary_radius;
  width: 100%;
  padding: 0.8rem;
  background: #f5f5f5;
  color: #7d7d7d;
  border: none;
  font-weight: 600;
  font-size: map-get($text-sizes, 'md');
}

.assistant-chat {
  height: 100%;

  .absolute-top-wrapper {
    position: fixed;
    top: 0;
    padding-top: 2rem;
    background: white;
    z-index: 10;
    opacity: 0;
    transition: 1000ms ease-in-out;
  }

  .messages-wrapper {
    padding-bottom: 4rem;
    padding-top: 6rem;
    margin-top: auto;
  }

  .absolute-bottom-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    opacity: 0;
    transition: 1000ms ease-in-out;
  }

  .search-btn {
    @extend %cursor-pointer;
    background: none;
    border: none;
    color: #8b8b8b;
    font-size: 2rem;
  }

  .assistant-messages {
    display: flex;
    flex-direction: column;
  }

  .user-message {
    @extend %flex-vertical-center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    animation-name: fadeUp;
    animation-duration: 0.5s;

    .text-wrapper {
      background: #1387fa;
      border-radius: 20px 20px 0px 20px;
      color: white;
      padding: 0.7rem;
      font-weight: 600;
      margin-right: 1.2rem;
      font-size: map-get($text-sizes, 'md');
    }

    .user-avatar {
      @include size(40px);
    }
  }

  .bot-answer {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    animation-name: fadeUp;
    animation-duration: 0.5s;

    .text-wrapper {
      color: #333;
      font-weight: 600;
      font-size: map-get($text-sizes, 'xl');
    }
  }
}

@keyframes fadeUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
