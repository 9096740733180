@each $mq in var(--mqs) {
	@if $(mq) == n {
		@each $ms, $z in (var(--ms)), (var(--z)) {
			.f\:$(z),
			.f-z\:$(z) {
				font-size: var(--z$(ms));
			}
		}
	} @else {
		@media (--mq-$(mq)) {
			@each $ms, $z in (var(--ms)), (var(--z)) {
				.f\:$(z)\@$(mq),
				.f-z\:$(z)\@$(mq) {
					font-size: var(--z$(ms));
				}
			}
		}
	}
}
