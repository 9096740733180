.group-sm-card {
  transition: 0.25s ease-in-out;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  &.active {
    @extend %background-primary;
  }

  &:hover {
    transform: scale(1.05);
  }

  .group-title {
    color: #999;
    font-weight: 600;
  }

  &.active .group-title {
    color: white;
  }
}

.group-general-info {
  .info-icon {
    color: #666;
    font-size: 5rem;
  }

  .info-amount {
    display: block;
    color: #666;
    font-size: 3rem;
    font-weight: 600;
  }

  .info-name {
    color: #666;
  }
}

.group-member-card {
  .member-avatar {
    @include size(80px);
  }

  .view-member-btn {
    color: #666;
    font-weight: 600;
  }
}

.group-type {
  @extend %flex-vertical-center;

  .group-type-icon {
    @include size(30px);
    @extend %background-primary;
    @extend %circle_radius;
    color: white;
    display: table;
    text-align: center;
    font-size: 1.1rem;

    &.--lg {
      @include size(40px);
      font-size: 1.3rem;
    }

    i {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .group-type-name {
    color: #999;
    font-weight: 600;
    font-size: 0.9rem;
    margin-left: 1rem;
  }
}

.group-card {
  .group-name {
    color: #333;
    font-weight: 600;
  }

  .group-info-row {
    color: #666;
    font-weight: 600;
    font-size: 1.1rem;
  }
}

.group-details-page {
  .page-navigation {
    @extend %flex-vertical-center;
    color: #333;
    font-size: 1.3rem;
    font-weight: 600;

    a {
      @extend %flex-vertical-center;
      color: inherit;
    }
  }
}

.categories-card {
  color: #666;
  font-size: 1rem;
}

.recent-activity-table {
  thead {
    th {
      border: none;
    }
  }
}

.content-info-card {
  .rate-label {
    color: #666;
  }

  .rate-number {
    color: #333;
  }
}

.tooltip-member-group {
  @extend %primary_radius;
  padding: 0;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-top: 0.6rem;

  .tooltip-arrow[data-placement*="bottom"]::after {
    border-color: transparent transparent #1387fa transparent;
  }

  .tooltip-header {
    @extend %background-primary;
    border-radius: 5px 5px 0 0;
    color: white;
    padding: 1rem;
    font-weight: 600;
  }

  .tooltip-body {
    padding: 1rem;
  }

  .tooltip-footer {
    border-top: $base-border;
    padding: 1rem;
  }
}

.group-member-stat-item {
  @extend %flex-vertical-center;

  .label {
    color: #666;
    margin-right: 0.9rem;
    margin-bottom: 0;
  }
}

.add-group-btn {
  border-radius: 100px;
  background: #0ae291;
  color: white;
  height: 2.4rem;
  width: 2.4rem;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 2.4rem;
  cursor: pointer;
  border: none;
}
