:root {
	--l-s: (d, t, n, l), (var(--l-s--default), var(--l-s--tight), var(--l-s--normal), var(--l-s--loose));
}

@each $mq in var(--mqs) {
	@each $short, $val in var(--l-s) {
		@if $(mq) == n {
			/*---
				title: $(short)
				section: Letter Spacing
				---

				```html
				<div class=".l-s:$(short)">l-s:$(short)</span>
				```
			*/
			.l-s\:$(short) {
				letter-spacing: $(val);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: $(short)
					section: Letter Spacing
					---

					```html
					<div class=".l-s:$(short)@$(mq)">l-s:$(short)@$(mq)</span>
					```
				*/
				.l-s\:$(short)\@$(mq) {
					letter-spacing: $(val);
				}
			}
		}
	}
}
