:root {
	--flx-direction: (row, row-reverse, column, column-reverse), (r, r-r, c, c-r);
	--flx-wrap: (nowrap, wrap, wrap-reverse), (n, w, w-r);
	--flx-j-c: (flex-start, flex-end, center, space-between, space-around), (f-s, f-e, c, s-b, s-a);
	--flx-a-i: (flex-start, flex-end, center, baseline, stretch), (f-s, f-e, c, b, s);
	--flx-a-c: (flex-start, flex-end, center, stretch, space-between, space-around), (f-s, f-e, c, s, s-b, s-a);
	--flx-a-s: (flex-start, flex-end, center, stretch, auto), (f-s, f-e, c, s, a);

}
@each $mq in var(--mqs) {
	@each $prop, $short in var(--flx-direction) {
		@if $(mq) == n {
			/*---
				title: Direction: $(prop)
				section: Flex
				---

				```html
				<div class=".flx-d:$(short)">flx-d:$(short)</span>
				```
			*/
			.flx-d\:$(short) {
				flex-direction: $(prop);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Direction: $(prop)
					section: Flex
					---

					```html
					<div class=".flx-d:$(short)@$(mq)">flx-d:$(short)@$(mq)</span>
					```
				*/
				.flx-d\:$(short)\@$(mq) {
					flex-direction: $(prop);
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@each $prop, $short in var(--flx-wrap) {
		@if $(mq) == n {
			/*---
				title: Wrap: $(prop)
				section: Flex
				---

				```html
				<div class=".flx-w:$(short)">flx-w:$(short)</span>
				```
			*/
			.flx-w\:$(short) {
				flex-wrap: $(prop);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Wrap: $(prop)
					section: Flex
					---

					```html
					<div class=".flx-w:$(short)@$(mq)">flx-w:$(short)@$(mq)</span>
					```
				*/
				.flx-w\:$(short)\@$(mq) {
					flex-wrap: $(prop);
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@each $prop, $short in var(--flx-j-c) {
		@if $(mq) == n {
			/*---
				title: Justify Content: $(prop)
				section: Flex
				---

				```html
				<div class=".j-c:$(short)">j-c:$(short)</span>
				```
			*/
			.j-c\:$(short) {
				justify-content: $(prop);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Justify Content: $(prop)
					section: Flex
					---

					```html
					<div class=".j-c:$(short)@$(mq)">j-c:$(short)@$(mq)</span>
					```
				*/
				.j-c\:$(short)\@$(mq) {
					justify-content: $(prop);
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@each $prop, $short in var(--flx-a-i) {
		@if $(mq) == n {
			/*---
				title: Align Items: $(prop)
				section: Flex
				---

				```html
				<div class=".a-i:$(short)">a-i:$(short)</span>
				```
			*/
			.a-i\:$(short) {
				align-items: $(prop);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Align Items: $(prop)
					section: Flex
					---

					```html
					<div class=".a-i:$(short)@$(mq)">a-i:$(short)@$(mq)</span>
					```
				*/
				.a-i\:$(short)\@$(mq) {
					align-items: $(prop);
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@each $prop, $short in var(--flx-a-c) {
		@if $(mq) == n {
			/*---
				title: Align Content: $(prop)
				section: Flex
				---

				```html
				<div class=".a-c:$(short)">a-c:$(short)</span>
				```
			*/
			.a-c\:$(short) {
				align-content: $(prop);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Align Content: $(prop)
					section: Flex
					---

					```html
					<div class=".a-c:$(short)@$(mq)">a-c:$(short)@$(mq)</span>
					```
				*/
				.a-c\:$(short)\@$(mq) {
					align-content: $(prop);
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@each $prop, $short in var(--flx-a-s) {
		@if $(mq) == n {
			/*---
				title: Align Self: $(prop)
				section: Flex
				---

				```html
				<div class=".a-s:$(short)">a-s:$(short)</span>
				```
			*/
			.a-s\:$(short) {
				align-self: $(prop);
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Align Self: $(prop)
					section: Flex
					---

					```html
					<div class=".a-s:$(short)@$(mq)">a-s:$(short)@$(mq)</span>
					```
				*/
				.a-s\:$(short)\@$(mq) {
					align-self: $(prop);
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@for $i from 0 to 10 {
		@if $(mq) == n {
			/*---
				title: Order: $i
				section: Flex
				---

				```html
				<div class=".ord:$i">ord:$i</span>
				```
			*/
			.ord\:$i {
				order: $i;
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Order: $i
					section: Flex
					---

					```html
					<div class=".ord:$i@$(mq)">ord:$i@$(mq)</span>
					```
				*/
				.ord\:$i\@$(mq) {
					order: $i;
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@for $i from 0 to 10 {
		@if $(mq) == n {
			/*---
				title: Grow: $i
				section: Flex
				---

				```html
				<div class=".flx-g:$i">flx-g:$i</span>
				```
			*/
			.flx-g\:$i {
				flex-grow: $i;
			}
		} @else {
			@media(--mq-$(mq)) {
				/*---
					title: Order: $i
					section: Flex
					---

					```html
					<div class=".flx-g:$i@$(mq)">flx-g:$i@$(mq)</span>
					```
				*/
				.flx-g\:$i\@$(mq) {
					flex-grow: $i;
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@for $i from 0 to 10 {
		@if $(mq) == n {
			.flx-s\:$i {
				flex-shrink: $i;
			}
		} @else {
			@media(--mq-$(mq)) {
				.flx-s\:$i\@$(mq) {
					flex-shrink: $i;
				}
			}
		}
	}
}

@each $mq in var(--mqs) {
	@if $(mq) == n {
		.flx-b\:a {
			flex-basis: auto;
		}
	} @else {
		@media(--mq-$(mq)) {
			.flx-b\:a\@$(mq) {
				flex-basis: auto;
			}
		}
	}
}
