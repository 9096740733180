:root {
	--t-a-options: (left, right, center, justify), (l, r, c, j);
}

@each $mq in var(--mqs) {
	@if $(mq) == n {
		@each $style, $var in var(--t-a-options) {
			/*---
				title: $(style)
				section: Font Style
				---

				```html
				<span class=".t-a:$(var)">t-a:$(var)</span>
				```
			*/
			.t-a\:$(var) {
				text-align: $(style);
			}
		}
	} @else {
		@media (--mq-$(mq)) {
			@each $style, $var in var(--t-a-options) {
				/*---
					title: $(style)
					section: Font Style
					---

					```html
					<span class=".t-a:$(var)\@$(mq)">t-a:$(var)@$(mq)</span>
					```
				*/
				.t-a\:$(var)\@$(mq) {
					text-align: $(style);
				}
			}
		}
	}
}

