$base-border: 1px solid #eee;
$text-sizes: (
  'sm': 0.95rem,
  'md': 1rem,
  'lg': 1.05rem,
  'xl': 1.1rem,
);
$component-colors: thread, course, comment, doc;
$transition-effect: 0.25s ease-in-out;

$orange: #f46025;
$green: #50a782;
$blue: #1387fa;
$red: #f32c2c;

$course: #db3700;
$doc: #076145;
$thread: #6610f2;

$gray: #666;

$link-decoration: none !important;

$theme-colors: (
  'orange': $orange,
  'gray': $gray,
  'course': $course,
  'doc': $doc,
  'thread': $thread,
);

$colors: (
  'black': #111111,
  'red': $red,
  'primary': $blue,
  'orange': $orange,
  'success': $green,
);

$border-radius: 5px;

$shed-pseudo-states: n, active, focus, hover, visited;
