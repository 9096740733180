.bg-white {
  background: white;
}

.flex-1 {
  flex: 1;
}

.btn:focus {
  box-shadow: none;
}

.article-separator {
  width: 61px;
}

.thread--main-image {
  @extend %primary_radius;
  margin-bottom: 1rem;
}
.mini-article {
  .article-body {
    .article-title {
      @extend %text-ov-2l;
      font-weight: 600;
      font-size: 1.05rem;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .article-comments-number {
      font-size: 14px;
      color: #777 !important;
    }
  }

  .article-footer {
    margin-top: 0.5rem;

    .views-container {
      .views-title {
        font-size: 14px;
        color: #777 !important;
      }

      .views-userlist {
        display: flex;
        margin-left: 0.5rem;

        .views-user-avatar {
          @include size(25px);
          margin-right: 0.4rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &.compact {
    box-shadow: none;
    border: $base-border;
    display: flex;
    flex-direction: column;
    height: 100%;

    .article-subhead {
      margin-bottom: 1rem;
    }

    .article-title {
      font-weight: 600;
      font-size: 1.05rem;
      color: #333;
      overflow: hidden;
      word-break: break-all;
      max-height: 120px;
      text-overflow: ellipsis;
    }

    .article-footer {
      display: flex;
      flex: 1;
    }
  }
}

.article-subhead {
  @extend %flex-vertical-center;

  .article-author {
    @extend %flex-vertical-center;

    .author-avatar {
      @include size(30px);
      margin-right: 0.6rem;
    }

    .author-name {
      font-weight: 500;
      line-height: normal;
      font-size: 15px;
      color: #444444;
    }
  }

  .article-pubdate {
    font-weight: 500;
    line-height: normal;
    font-size: 15px;
    color: #444444;
    margin-left: auto;
  }
}

.round-pill-container .round-pill {
  @extend %transition-animation;
  @extend %background-primary;
  box-shadow: 0px 0px 3.73057px rgba(0, 0, 0, 0.17);
  border-radius: 3.35751px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 0.8rem;
  display: inline-block;
  padding: 0.3rem 0.4rem;
  color: #ffffff;
  width: auto;
  margin: 0.2rem 0.5rem 0.2rem 0;

  &.inactive {
    opacity: 0.4;
  }

  &.green {
    background: #0ae291;
  }

  &:last-child {
    margin-right: 0;
  }
}

.post-title-op {
  color: #333;
  font-size: 1.1rem;
}

.post-title {
  color: #333;
  font-size: 1rem;
}

.thread-viewers-card {
  ul {
    li {
      @extend %flex-vertical-center;
      margin-bottom: 0.5rem;

      .userAvatar {
        height: 30px;
        width: 30px;
        margin-right: 0.3rem;
      }

      span {
        color: #666;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.threadCreateCard {
  .content-input {
    @extend %primary_radius;
    display: flex;
    flex-direction: column;
    padding: 0.6rem 1rem;
    width: 100%;
    background: #f1f4f5;
    color: #bbb;
    cursor: text;
    transition: 0.1s ease-in-out;
    font-size: 1rem;

    .textarea {
      min-height: 1.3rem;
      height: 100%;
      width: 100%;
    }
  }

  &.modalOpened .content-input {
    @extend %primary_radius;
    color: #9b9eb8;
    animation: changeHeight 0.5s forwards;
    background: #eef0f4;
  }

  .btn-primary {
    border: none;
    width: 100px;
    background-color: #0455fe;
  }

  .btn-no-bg {
    // @extend %transition-animation;
    // border: none;
    // width: 100px;
    // background: none;
    color: #aaa;
    &:hover {
      color: #888;
    }
  }
}

@keyframes changeHeight {
  0% {
    min-height: 0;
  }

  100% {
    min-height: 5rem;
  }
}

.pill-card {
  .pill-title {
    margin-top: 10px;
    text-align: center;
    color: #555;
    font-size: 24px;
    font-weight: 700;

    i {
      margin-bottom: 2px;
      margin-right: 10px;
    }
  }

  .article-separator {
    margin-left: auto;
    margin-right: auto;
  }

  .pill-body {
    text-align: center;

    .pill-question {
      font-size: 18px;
      color: #000;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .pill-answer {
      @extend %primary_radius;
      color: #555;
      padding: 20px;
      background-color: rgba(52, 204, 67, 0.2);

      h6 {
        font-size: 18px;
      }

      i {
        color: rgb(52, 204, 67);
        font-size: 20px;
      }
    }

    .pill-buttons {
      .pill-btn {
        @extend %primary_radius;
        margin: 10px;
        font-weight: 700;
        font-size: 14px;
      }

      .afirmative {
        color: #34cc43;
        background-color: rgba(52, 204, 67, 0.2);
      }

      .negative {
        color: #de5050;
        background-color: rgba(222, 80, 80, 0.21);
      }
    }
  }
}

.thread-sc-card {
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 1.05rem;
  }

  .card-body {
    display: flex;
    flex-direction: column;
  }

  .circle-add-btn {
    @include size(5rem);
    @extend %circle_radius;
    @extend %color-primary;
    @extend %flex-all-center;
    background: rgba(19, 135, 250, 0.29);
    font-size: 2.5rem;
  }
}

.css-1pcexqc-container {
  font-weight: 600;
  color: #333;
}

.select-groups__multi-value {
  color: #fff;
}

.select-categories__multi-value {
  color: #fff;
}

.select-categories__multi-value__label {
  color: #fff !important;
}

.select-groups__multi-value__label {
  color: #fff !important;
}

.swal2-cancel {
  background-color: #fff0 !important;
  color: #333 !important;
}

.thread--files-list li {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.thread--file-item {
  @extend %btn-no-bg;
  @extend %flex-vertical-center;
  border: $base-border;
  border-radius: 5px;
  padding: 0.8rem;
}
