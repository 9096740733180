/*********************************
2. Body and some general stuff
*********************************/

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html {
  height: unset;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
  color: #a5a5a5;
  overflow-x: hidden;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

button,
input,
textarea {
  outline: none !important;
}

.duc-icon {
  top: -1px;
  position: relative;
  svg {
    width: 1em;
    height: 1em;
  }
  svg.mdi-icon {
    width: 1.1em;
    height: 1.1em;
  }
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
}

[class^="icon-"] {
  vertical-align: unset !important;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.wh-s\:no {
  white-space: nowrap;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-wrapper {
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.body-content-v1 {
  background: none;
}

#basic-footer {
  background: none;
  margin-top: auto;
}

// *****************************
// Cores e utilidades
// *****************************

.ov-auto {
  overflow: auto;
}

.visibility-50 {
  opacity: 0.5;
}

.flex-nowrap {
  flex-wrap: nowrap;
  margin-right: 0;
  margin-left: 0;

  & > :first-child {
    padding-left: 0;
  }

  & > :last-child {
    padding-right: 0;
  }
}

body {
  margin: 0;
}

.popover {
  font-family: inherit;
}

.btn--lg {
  font-weight: 600;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.border-left {
  border-left: 2px solid #ccc;
}

[contenteditable="true"]:focus {
  outline: none;
}

.break-text {
  word-break: break-all;
}

.flex-vertical-center {
  @extend %flex-vertical-center;
}

.full-page {
  display: flex;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
}
// image remove on hover
.img-rm-hover {
  cursor: pointer;
  display: inline-block;
  img {
    display: block;
  }
  &:hover {
    &:after {
      @extend %material-icons;
      @extend %flex-all-center;
      @include size(100%);
      transition: $transition-effect;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      content: "\f156";
      background: rgba(0, 0, 0, 0.2);
      color: rgba(255, 255, 255, 1);
    }
  }
}

.btn-outline {
  @extend %transition-animation;
  border: $base-border;
  color: #666666aa;

  &:hover {
    color: #666666;
  }
}

.btn-no-bg {
  @extend %btn-no-bg;
}
// hidden file input
.hfi-wrapper {
  input[type="file"] {
    @include size(100%);
    position: absolute;
    display: none;
    top: 0;
    left: 0;
  }
}

.btn-like-blue {
  @extend %color-primary;
  @extend %transition-animation;
  font-size: 18px;

  &:hover {
    @extend %color-primary;
  }
}

.btn-like-gray {
  @extend %transition-animation;
  font-size: 18px;
  color: #666;

  &:hover {
    @extend %color-primary;
  }
}

.bolded {
  font-weight: 600;
}

.dark-gray {
  color: #333;
}

.gray {
  color: #666;
}

.light-gray {
  color: #999;
}

.pointer {
  @extend %cursor-pointer;
}

.zIndexUp {
  z-index: 1051 !important;
  height: 112px;
}

.form-control {
  background: none !important;
}

.modal-content {
  @extend %primary_radius;

  .modal-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.userAvatar {
  @extend %circle_radius;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-card {
  @extend %transition-animation;

  &.--outlined {
    box-shadow: none;
    border: $base-border;

    &:hover {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    }
  }

  .card-body {
    padding: 1.3rem;
  }
}

.middledotlist_container {
  .middledotlist_item {
    color: #999;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;

    &:after {
      content: " \B7\00a0";
    }

    &:last-child:after {
      content: "";
    }
  }
}

.grayed_text_area-container {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 0.8rem;
  .grayed_text_area {
    border-radius: 5px 5px 0 0;
    font-size: map-get($text-sizes, "sm");
    color: #6d6d6d;
    padding-bottom: 0.4rem;
    min-height: 5rem;
    border: none;
    outline: none;
  }

  .grayed_text_area-footer {
    border-radius: 0 0 5px 5px;
    padding-top: 0.4rem;
    display: flex;

    .btn {
      @extend %primary_radius;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0.3rem 0.5rem;
    }
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.horizontal-avatar-li-container {
  @extend %flex-vertical-center;

  li {
    margin-right: 0.2rem;
    height: 30px;

    &.avatar-item {
      width: 30px;
    }

    .avatar-lg-btn {
      @extend %circle_radius;
      @extend %flex-vertical-center;
      @extend %transition-animation;
      color: #515151;
      background: #e9e9e9;
      height: 100%;
      padding: 0 0.5rem;
      font-weight: 500;

      &:hover {
        background: #aaa;
        color: white;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.return-btn {
  @extend %flex-all-center;
  color: #333;
  height: 21px;
  font-size: 1rem;
}

.stepped-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(244, 96, 37, 0.57) 0%,
    rgba(244, 96, 37, 0.56) 100%
  );

  .step-progress-bar {
    width: 100%;
    margin: 0;
    height: 5px;
    background: rgba(255, 255, 255, 0.34);
    display: flex;

    .step-current-progress {
      height: 5px;
      background: rgb(255, 255, 255);
      min-width: 0;
      @extend %transition-animation;
    }
  }

  .page-content {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
}

.dropzone {
  @extend %flex-vertical-center;
  @extend %primary_radius;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border: 2px dashed #ccc;
  background: none;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  .icon {
    color: #666;
    font-size: 6rem;
  }

  .help-text {
    font-size: 1.1rem;
    color: #666;
    opacity: 0.9;
    text-align: center;
  }

  .open-button {
    @extend %cursor-pointer;
    font-weight: bold;
    border-bottom: 2px solid #666;
    opacity: 0.9;
  }

  .thumb-wrapper {
    @include size(120px);
    margin-bottom: 0.8rem;

    .thumb {
      @include size(100%);
      @extend %primary_radius;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.dropzone-fullsize {
    padding: 0;

    .thumb-wrapper {
      @include size(100%);
      @extend %primary_radius;
      margin-bottom: 0;

      .thumb {
        background-size: cover;
      }
    }

    .help-text {
      @extend %flex-vertical-center;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 20px;
      transition: 0.25s ease-in-out;
      color: white;

      &:not(:hover) {
        opacity: 0;
      }
    }
  }
}

.colorSelect {
  .color {
    input {
      display: none;

      &:checked + label {
        border-right: 1px solid #999;

        span {
          margin-right: 0.6rem;
        }
      }
    }

    label {
      margin-right: 0.6rem;

      span {
        @include size(35px);
        @extend %primary_radius;
        @extend %cursor-pointer;
        @extend %transition-animation;
        display: block;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

.viewed-btn {
  @include size(40px);
  @extend %background-primary;
  @extend %circle-colored-btn;
  font-size: 1.3rem;

  &:active {
    background: #0f6cc9;
  }

  &.sm {
    width: 35px;
    height: 35px;
    font-size: 1.1rem;
  }

  &.xxs {
    width: 25px;
    height: 25px;
    font-size: 0.8rem;
  }
}

.inline-radial-btn {
  @extend %flex-vertical-center;
  @extend %cursor-pointer;
  @extend %transition-animation;
  background: none;
  border: none;
  outline: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;

    .viewed-btn {
      box-shadow: 0px 0px 8.46154px rgba(0, 0, 0, 0.4);
    }
  }

  span {
    margin-left: 0.7rem;
    font-size: 0.9rem;
    color: #666;
    font-weight: 600;
  }
}

.create-content-type {
  @include size(2rem);
  @extend %background-primary;
  @extend %flex-all-center;
  @extend %circle_radius;

  color: white;
  font-size: 1rem;
}

.carousel-indicators {
  bottom: 0px;

  & > li {
    @include size(10px);
    @extend %circle_radius;
    background: #00000044;

    &.active {
      background: #00000077;
    }
  }
}

.pdf-viewer--options-wrapper {
  background: #333333aa;
  display: inline-flex;
  align-items: center;
  position: sticky;
  padding: 0.8rem;
  border-radius: 5px;
  bottom: 1rem;
  color: white;
}

.pdf-viewer--page-number {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
}

.pdf-viewer--page-change-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.3rem;
  width: 2rem;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }
}
.swal2-popup {
  border-radius: 16px !important;
}
.swal2-confirm,
.swal2-styled {
  &.btn {
    border-radius: 5px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    display: inline-block !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    border: none !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    opacity: 0.8;

    &:hover {
      text-decoration: none !important;
      opacity: 1;
    }
  }

  &.btn-no-bg {
    background: none !important;
    color: #666 !important;
  }

  &.btn-danger {
    color: #fff !important;
    background: #ff5b5b !important;
    border: none !important;
    font-weight: 500 !important;

    &:hover {
      color: #fff !important;
    }
  }

  &.btn-lg {
    padding: 0.5rem 1rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
    border-radius: 0.3rem !important;
  }
}

.create-grp-backdrop,
.create-grp-modal {
  z-index: 1060;
}

.ant-tree-checkbox,
.ant-tree-checkbox-inner {
  border-radius: $border-radius !important;
}

.feather-icon {
  width: 1em;
  height: 1em;
}

@include generate-theme($colors);

.ant-btn-success {
  background-color: $green;
  border-color: $green;
  &:hover {
    background: lighten($green, 10%);
    border-color: $green;
  }
  &:active,
  &:focus {
    border-color: $green;
    background: darken($green, 10%);
  }
}

// SHED-LIKE-CSS

c\:yellow {
  color: yellow;
}
d\:f {
  display: flex;
}
a-i\:c {
  align-items: center;
}
j-c\:c {
  justify-content: center;
}

// ENDOF SHED-LIKE-CSS

.anticon svg {
  vertical-align: baseline;
}
