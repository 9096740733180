:root {
	--pseudo-states: n, active, focus, hover, visited;
	--t-d: (n, u, l-t), (none, underline, line-through);
}
@each $mq in var(--mqs) {
	@each $state in var(--pseudo-states) {
		@if $(state) == n {
			@each $short, $val in var(--t-d) {
				@if $(mq) == n {
					.t-d\:$(short) {
						text-decoration: $(val);
					}
				} @else {
					@media(--mq-$(mq)) {
						.t-d\:$(short)\@$(mq) {
							text-decoration: $(val);
						}
					}
				}
			}
		} @else {
			@each $short, $val in var(--t-d) {
				@if $(mq) == n {
					.$(state)\/t-d\:$(short) {
						@nest &:$(state) {
							text-decoration: $(val);
						}
					}
				} @else {
					@media(--mq-$(mq)) {
						.$(state)\/t-d\:$(short)\@$(mq) {
							@nest &:$(state) {
								text-decoration: $(val);
							}
						}
					}
				}
			}
		}
	}
}
