.skills-chart {
  svg {
    overflow: visible;
  }

  .scale {
    fill: #f8f8f8;
  }

  .radar-chart--dot {
    fill: #f8f8f8 !important;
    stroke-width: 5px !important;
    cursor: default !important;
  }

  .radar-chart--dot:hover {
    r: 5;
  }

  .shape {
    stroke: none;
    fill: var(--primary-color);
    fill-opacity: 0.7;
  }

  .caption {
    font-weight: 600;
    font-family: "Inter";
    font-size: 0.9rem;
    fill: #666;
    cursor: default;
  }
}

.profile-header--ci-item {
  font-size: 1rem;
}

.profile-header--ci-item-lb {
  color: #aaa;
  display: inline-block;
  margin-bottom: 0;
}

.profile-header--ci-item-n {
  color: #666;
  display: inline-block;
  margin-bottom: 0;

  @include respond-above(lg) {
    margin-left: 0.5rem;
  }
}

.mb-skill-ls {
  .mb-skill-li {
    border-right: $base-border;
    display: flex;
    justify-content: center;

    &:last-child {
      border-right: none;
    }
  }
}

.mb-skill-it {
  display: inline-flex;
  align-items: center;
}

.mb-skill-it--title {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 0;
}

.mb-skill-it--level {
  color: #999;
  font-size: 0.9rem;
}
