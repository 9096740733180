:root {
	--pos-options: (absolute, relative, static, fixed, cover), (a, r, s, f, c);
}

@each $mq in var(--mqs) {
	@if $(mq) == n {
		@each $position, $pos in var(--pos-options) {
			@if $(position) == cover {
				.pos\:$(pos) {
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
			} @else {
				.pos\:$(pos) {
					position: $(position);
				}
			}
		}
	} @else {
		@media (--mq-$(mq)) {
			@each $position, $pos in var(--pos-options) {
				@if $(position) == cover {
					.pos\:$(pos)\@$(mq) {
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				} @else {
					.pos\:$(pos)\@$(mq) {
						position: $(position);
					}
				}
			}
		}
	}
}
