// VARS
:root {
  --primary-color: #1387fa;
  --success-color: #50a782;
  --danger-color: #ff5b5b;
  --ck-color-toolbar-border: rgb(217, 217, 217) !important;
  --ck-color-base-border: rgb(217, 217, 217) !important;
  --ck-border-radius: 5px !important;
}

$break-large: 1200px;

// MIXINS
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin transform-rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

// PLACEHOLDERS
%circle_radius {
  border-radius: 100% !important;
}

%primary_radius {
  border-radius: 5px;
}

%transition-animation {
  transition: 0.25s ease-in-out;
}

%cursor-pointer {
  cursor: pointer;
}

%flex-vertical-center {
  display: flex;
  align-items: center;
}

%flex-all-center {
  @extend %flex-vertical-center;
  justify-content: center;
}

%absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

%centered-covered-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

%material-icons {
  font-size: inherit;
  font-family: 'Material Design Icons';
  line-height: 1;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// colors
%color-primary {
  color: var(--primary-color) !important;
}

%background-primary {
  background: var(--primary-color) !important;
}

%color-success {
  color: var(--success-color) !important;
}

%background-success {
  background: var(--success-color) !important;
}

%background-danger {
  background: var(--danger-color) !important;
}

%course-color {
  color: #ff6347 !important;
}

%thread-color {
  @extend %color-primary;
}

%comment-color {
  @extend %color-success;
}

%doc-color {
  color: #fdcb5c !important;
}

%course-background {
  background: #ff6347 !important;
}

%thread-background {
  @extend %background-primary;
}

%doc-background {
  background: #fdcb5c !important;
}

%comment-background {
  @extend %background-success;
}

// bg and colors

%bg-primary-c-white {
  @extend %background-primary;
  color: white;
}

// Components

%circle-colored-btn {
  cursor: pointer;
  border-radius: 100%;
  box-shadow: none !important;
  color: white;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-in-out;
  opacity: 0.9;
  border: none;
  outline: none;

  &.bg-white {
    color: black;
  }

  &:hover {
    opacity: 1;
    color: white;

    &.bg-white {
      color: black;
    }
  }
}

%btn-no-bg {
  @extend %transition-animation;
  @extend %cursor-pointer;
  color: #666;
  background: none;
  font-weight: 600;
  border: none;

  &:hover {
    color: #555;
  }

  &.active {
    color: #333;
  }
}

%text-ov-2l {
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) ==false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) ==false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin generate-theme($theme_colors) {
  @each $label, $color in $theme_colors {
    @each $state in $shed-pseudo-states {
      @if $state == n {
        .bg\:#{$label} {
          background: $color;
        }

        .c\:#{$label} {
          color: $color;
        }

        @for $i from 1 through 9 {
          .bg\:#{$label}\.#{$i} {
            background: transparentize($color, ($i / 10));
          }

          .c\:#{$label}\.#{$i} {
            color: transparentize($color, ($i / 10));
          }
        }
      } @else {
        .#{$state}\/bg\:#{$label}:#{$state} {
          background: $color;
        }
        .#{$state}\/c\:#{$label}:#{$state} {
          color: $color;
        }

        @for $i from 1 through 9 {
          .#{$state}\/bg\:#{$label}\.#{$i}:#{$state} {
            background: transparentize($color, ($i / 10));
          }
          .#{$state}\/c\:#{$label}\.#{$i}:#{$state} {
            color: $color !important;
          }
        }
      }
    }
  }
}
