:root {
	--v-a-options: (middle, baseline, top, bottom, center), (m, bl, t, b, c);
}

@each $mq in var(--mqs) {
	@if $(mq) == n {
		@each $style, $var in var(--v-a-options) {
			/*---
				title: $(style)
				section: vertical-alignment
				---

				```html
				<span class=".v-a:$(var)">v-a:$(var)</span>
				```
			*/
			@if $(style) == center {
				.v-a\:$(var) {
					top: 50%;
					transform: translateY(-50%);
				}
			} @else {
				.v-a\:$(var) {
					vertical-align: $(style);
				}
			}
		}
	} @else {
		@media (--mq-$(mq)) {
			@each $style, $var in var(--v-a-options) {
				/*---
					title: $(style)
					section: vertical-alignment
					---

					```html
					<span class=".v-a:$(var)\@$(mq)">v-a:$(var)@$(mq)</span>
					```
				*/
				@if $(style) == center {
					.v-a\:$(var) {
						top: 50%;
						transform: translateY(-50%);
					}
				} @else {
					.v-a\:$(var)\@$(mq) {
						vertical-align: $(style);
					}
				}
			}
		}
	}
}

