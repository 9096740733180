:root {
	--f-w-min: 100;
	--f-w-max: 900;
}
@each $mq in var(--mqs) {
	@if $(mq) == n {
		@for $weight from var(--f-w-min) to var(--f-w-max) by 100 {
			/*---
				title: Font Weight
				section: Typography
				---

				```html
				<span class=".f-w:$(weight)">f-w:$(weight)</span>
				```
			*/
			.f-w\:$(weight) {
				font-weight: $(weight);
			}
		}
	} @else {
		@media (--mq-$(mq)) {
			@for $weight from var(--f-w-min) to var(--f-w-max) by 100 {
				/*---
					title: Font Weight
					section: Typography
					---

					```html
					<span class=".f-w:$(weight)@$(mq)">f-w:$(weight)@$(mq)</span>
					```
				*/
				.f-w\:$(weight)\@$(mq) {
					font-weight: $(weight);
				}
			}
		}
	}
}
