:root {
	--f-s-styles: (normal, italic, oblique), (n, i, o);
}

@each $mq in var(--mqs) {
	@if $(mq) == n {
		@each $style, $sshort in var(--f-s-styles) {
			/*---
				title: $(style)
				section: Font Style
				---

				```html
				<span class=".f-s:$(sshort)">f-s:$(sshort)</span>
				```
			*/
			.f-s\:$(sshort) {
				font-style: $(style);
			}
		}
	} @else {
		@media (--mq-$(mq)) {
			@each $style, $sshort in var(--f-s-styles) {
				/*---
					title: $(style)
					section: Font Style
					---

					```html
					<span class=".f-s:$(sshort)\@$(mq)">f-s:$(sshort)@$(mq)</span>
					```
				*/
				.f-s\:$(sshort)\@$(mq) {
					font-style: $(style);
				}
			}
		}
	}
}
