%large_padding {
  @media screen and (min-width: $break-large) {
    padding: 2rem 3rem;
  }
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.course-card {
  .ant-progress-outer,
  .ant-progress-inner,
  .ant-progress {
    display: block;
  }
  .ant-progress-inner {
    background-color: white;
  }
  .ant-progress-inner,
  .ant-progress-bg {
    border-radius: 0;
  }
  .card-body,
  .card-footer {
    padding: 1rem;
  }
}

.course-card .card-header--image-container {
  width: 100%;
  padding-top: 55%;
  border-radius: 9px 9px 0 0;

  & > * {
    @include size(100%);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.course-card .card-header--image {
  border-radius: 9px 9px 0 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: $base-border;
}

.course-card .course-title {
  @extend %text-ov-2l;
  color: #333;
  font-size: 15px;
  font-weight: 600;
}

.module--back-intro-btn {
  @extend %transition-animation;
  @extend %cursor-pointer;
  font-size: 1rem;
  color: white;
  opacity: 0.8;

  &:hover {
    color: white;
    opacity: 1;
  }
}

.module--last-edited {
  font-size: 1rem;
  color: white;
  opacity: 0.8;
}

.header--course-image-container {
  overflow: hidden;
  height: 0;
  padding-top: 55%;
  width: 100%;

  & > * {
    @extend %primary_radius;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-position: center;
    background-size: cover;
  }
}

.course-reader {
  .header--icon {
    @include size(35px);
    @extend %circle_radius;
    @extend %flex-all-center;
    color: white;
  }

  .course-reader--header {
    width: 100%;
    min-height: 250px;
    background-image: linear-gradient(
      to right top,
      rgb(115, 115, 115),
      rgb(38, 38, 38)
    );
    display: flex;

    .btn-no-bg {
      color: white;
      opacity: 0.8;
      font-size: 1.1rem;

      &:hover {
        color: white;
        opacity: 0.9;
      }
    }

    .course--aditional-details {
      border-width: 1px;
      border-color: #e8eaed;
      border-left-style: solid;
    }

    .course-reader--course-info-card {
      height: 100%;
      top: 5rem;
    }

    .course-info-card--body {
      @extend %large_padding;
      color: black;
      margin: auto 0;
      width: 100%;

      .full-width--inner-card {
        margin-left: calc(-3rem - 1.3rem);
        margin-right: calc(-3rem - 1.3rem);
        padding-left: calc(3rem + 1.3rem);
        padding-right: calc(3rem + 1.3rem);
        border-bottom: 1px solid #ddd;
      }

      .header--course-description {
        font-size: 1.1rem;
        color: #888;
      }

      .header--course-company-name {
        opacity: 0.8;
        margin-top: 4rem;
      }
    }
  }

  .course-reader--module-header {
    height: 100%;
    padding: 0 0 40px 0;

    .course-title {
      color: white;
      font-weight: 500;
    }
  }

  .course-reader--module-body {
    .module--sidebar {
      margin-top: 2rem;

      .modules-list {
        margin-top: -0.5rem;
        .modules-list--item {
          color: #333;
          font-weight: 600;
          padding: 0.5rem 0;

          &:not(.active) {
            opacity: 0.7;
          }

          a {
            font-size: 1.05rem;
            color: inherit;
            display: flex;
            width: 100%;
          }

          .module-number-icon {
            font-size: 1.2rem;
            font-weight: 600;
            width: 2rem;
          }

          .module-time {
            color: #666;
            font-size: 0.8rem;
          }
        }
      }
    }

    .module--content-body {
      margin-top: -100px;
      @media screen and (max-width: 992px) {
        margin-top: -70px;
      }

      .card-header {
        @extend %large_padding;
        border-bottom: $base-border;
        background: white;
      }

      .card-body {
        @extend %large_padding;
      }

      .module-title {
        color: #333;
        margin-bottom: 0;
      }

      .module--text-content {
        color: black;

        h1 {
          text-align: center;
          font-size: 40px;
        }

        h2 {
          font-size: 36px;
        }

        h3 {
          font-size: 28px;
        }

        h4 {
          font-size: 22px;
        }

        p {
          font-size: 20px;
          line-height: 1.5;
          font-weight: 300;
        }

        blockquote {
          overflow: hidden;
          padding-right: 1.5em;
          padding-left: 1.5em;
          margin-left: 0;
          margin-right: 0;
          font-style: italic;
          border-left: 5px solid;
        }

        iframe {
          width: 100%;
          height: -moz-available;
          height: -webkit-fill-available;
          height: fill-available;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        li {
          font-size: 20px;
          line-height: 2;
          font-weight: 300;
          list-style-position: inside;
        }

        ul {
          list-style-type: disc;
          padding-inline-start: 40px;
        }

        ol {
          padding-inline-start: 40px;
        }

        table {
          border-color: rgba(0, 0, 0, 1);
        }

        td {
          font-size: 16px;
          line-height: 2;
          font-weight: 300;
          color: rgba(0, 0, 0, 1);
          padding: 3px;
          border-color: rgba(0, 0, 0, 1);
        }

        tr {
          border-color: rgba(0, 0, 0, 1);
        }

        .text-tiny {
          font-size: 12px;
        }
        .text-small {
          font-size: 15px;
        }
        .text-big {
          font-size: 24px;
        }
        .text-huge {
          font-size: 32px;
        }
        .marker-yellow {
          background-color: #fdfd77;
        }
        .marker-green {
          background-color: #63f963;
        }
        .marker-pink {
          background-color: #fc7999;
        }
        .marker-blue {
          background-color: #72cdfd;
        }
        .marker-pink {
          background-color: #fc7999;
        }
        .pen-red {
          color: #e91313;
        }
        .pen-green {
          color: #180;
        }
        .media {
          display: block;
        }
      }
    }
  }

  .course--main-sections-list {
    margin-top: 8rem;

    .sections-list--title {
      color: #333;

      i {
        color: #ff6347;
        margin-right: 1rem;
      }
    }
  }
}

.course-module--card {
  height: 100%;

  .module-card--order-number {
    @include size(35px);
    @extend %circle_radius;
    @extend %flex-all-center;
    font-weight: 700;
    background-color: #f4f4f4;
    color: #666;
    margin-bottom: 0;
  }

  .module-card--module-title {
    color: #333;
    margin-bottom: 0;
  }

  .module-card--subheader-details {
    color: #999;
    font-size: 1rem;

    i {
      color: #ff6347;
      margin-right: 0.3rem;
    }
  }

  .module-card--status-icon {
    @include size(30px);
    @extend %circle_radius;
    @extend %flex-all-center;
    background-color: #ddd;
    color: white;
    margin-bottom: 0;

    &.status--completed {
      background: var(--success-color);
    }

    &.status--active {
      background: var(--primary-color);
    }

    &.status--locked {
      background: #ccc;
      font-size: 0.9rem;
    }
  }
}

.quiz-item__wrapper {
  &:not(.regular-pad) {
    .card-header,
    .card-body {
      @extend %large_padding;
    }
  }

  &.regular-pad {
    .card-header {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .card-header {
    background: none;
    border-bottom: none;
  }

  .card-body {
    margin-bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .quiz-item__subheader-text {
    color: #999;
    font-weight: 600;
  }

  .quiz-item__question {
    color: #333;
  }
}

.quiz-answer-item {
  input {
    display: none;
  }

  .quiz-answer-item--label-container {
    @extend %flex-vertical-center;

    &.label-hover-all {
      @extend %cursor-pointer;
    }

    .quiz-answer-item__order-number {
      @include size(35px);
      @extend %circle_radius;
      @extend %flex-all-center;
      @extend %transition-animation;
      border: 2px solid #ccc;
      color: #999;
      font-weight: 600;
      font-size: 1.1rem;
      margin-right: 2rem;

      &:hover {
        @extend %cursor-pointer;
        background: #ccc;
        color: white;
      }
    }

    &.label-hover-all:hover {
      .quiz-answer-item__order-number {
        background: #ccc;
        color: white;
      }
    }

    .quiz-answer-item__answer {
      @extend %transition-animation;
      color: #999;
      font-weight: 600;
      font-size: 1.1rem;
      flex: 1;
    }

    .quiz-answer-item__remove-button {
      @extend %btn-no-bg;
      font-size: 1.2rem;
    }
  }

  input:checked + .quiz-answer-item--label-container {
    .quiz-answer-item__order-number {
      @extend %background-primary;
      border-color: var(--primary-color);
      color: white;
    }

    .quiz-answer-item__answer {
      color: #666;
    }
  }
}

.module-arrows__wrapper {
  display: flex;
  justify-content: space-between;

  .module-arrows__item {
    .arrow-item__subheader {
      color: #666;
      font-size: 0.85rem;
      display: block;
    }

    .arrow-item__arrow-icon {
      color: #333;
      font-size: 1.3rem;
      display: inline-block;
    }

    .arrow-item__title {
      color: #333;
      font-weight: 600;
      display: inline-block;
    }
  }
}

// Editor
.course-text--p {
  color: #666;
  margin-bottom: 0;
}

.course-text--h2 {
  color: #000;
}

.course-text--h3 {
  color: #333;
}

.course-text--h4 {
  color: #666;
}

%editor-textarea {
  color: #666;
  border: none;
  outline: none;
  width: 100%;
  background: none;
}

.course-title--input {
  @extend %editor-textarea;
  font-size: 1.7rem;
}

.course-description--input {
  @extend %editor-textarea;
  font-size: 1.1rem;
}

.module-title--input {
  @extend %editor-textarea;
  font-size: 1.7rem;
  font-family: "Merryweather";
}

.test-question--input {
  @extend %editor-textarea;
  font-size: 1.2rem;
}

.editor_fixed-actions__wrapper {
  position: sticky;
  top: 3rem;
}

.editor_fixed-action__btn {
  @extend %circle-colored-btn;
  @include size(45px);
  font-size: 1.2rem;

  &.btn-action__save {
    @extend %background-success;
    &.shake {
      animation: shake-animation 3.72s ease infinite;
      transform-origin: 50% 50%;
    }
  }

  &.btn-action__delete {
    @extend %background-danger;
  }
}

.ckeditor-text {
  font-size: 16px;
  color: #666;

  ol {
    padding-inline-start: 40px;
  }

  ul {
    padding-inline-start: 40px;
    list-style: disc;
  }
}

.quiz--editor {
  .card-header {
    background: white;
  }
}

.quiz-title--input {
  @extend %editor-textarea;
  font-size: 1.2rem;
}

.quiz-answer--input {
  border: none;
}

.quiz-editor--button {
  @extend %circle-colored-btn;
  @include size(30px);
  background: #bbb;
}

.instyle-textarea {
  border: none;
  background: none;
  width: 100%;
}

.grade-tests--member {
  @extend %flex-vertical-center;

  .member--avatar {
    @include size(40px);

    &.member--avatar__sm {
      @include size(30px);
    }
  }

  .member--name {
    color: #333;
    margin-bottom: 0;
  }
}

.grade-tests--answer-actions {
  display: flex;
  justify-content: right;

  .action-btn {
    @extend %circle-colored-btn;
    @include size(30px);
    font-size: 0.9rem;
    border-radius: 30px !important;
    font-weight: 600;

    &.active {
      width: unset;
      padding: 0 0.5rem;

      i {
        margin-right: 0.5rem;
      }
    }

    i {
      font-size: 1.1rem;
    }
  }
}

.final-test--question-list {
  li:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.answer-question--container {
  &:hover {
    box-shadow: none !important;
  }
}

.answer-question--textarea {
  @extend %editor-textarea;
  color: #666;
  font-size: 1.1rem;
}

.course-objectives--list {
  .course-objectives--item {
    @extend %flex-vertical-center;
    color: #666;
    font-size: 1.1rem;
    flex-wrap: wrap;

    input {
      @extend %editor-textarea;
      font-size: inherit;
      flex: 1;
    }

    p {
      font-size: inherit;
      margin-bottom: 0;
      flex: 1;
    }

    &:before {
      @extend %material-icons;
      content: "\F12C";
      width: 2rem;
      font-size: 1.1rem !important;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .course-objectives--remove-btn {
    @extend %btn-no-bg;
    margin-left: auto;
  }
}

.module--content-option-btn {
  background: none;
  cursor: pointer;
  border: none;
  color: #999;
  font-size: 2.2rem;
  margin-bottom: 0;
  opacity: 0.8;
  transition: 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    @extend %color-primary;
  }
}

.module--pdf-file-input {
  display: none;
}

.reorderable-module--icon {
  font-size: 1.4rem;
  margin-left: 0.5rem;
  color: #666;
  transition: 0.25s ease-in-out;
  cursor: row-resize;

  &:hover {
    color: #333;
  }
}

.input--show-editable {
  input,
  textarea {
    margin-bottom: 3px;

    &:focus + label {
      opacity: 0;
    }
  }

  label {
    color: #aaa;
    margin-bottom: 0;
    margin-top: 4px;
    opacity: 1;
    transition: 0.25s ease-in-out;
    font-size: 0.8rem;
    display: block;
  }
}

.input--show-enter {
  input,
  textarea {
    margin-bottom: 3px;

    &:focus + label {
      opacity: 1;
      max-height: 2rem;
    }
  }

  label {
    color: #aaa;
    margin-bottom: 0;
    margin-top: 4px;
    opacity: 0;
    transition: 0.25s ease-in-out;
    font-size: 0.8rem;
    display: block;
    max-height: 0;
    width: 100%;
  }
}

.course-stat--rp {
  .CircularProgressbar-text {
    fill: #000 !important;
    font-weight: 600;
  }

  &.course-stat--rp-progress-ration {
    .CircularProgressbar-path {
      stroke: var(--primary-color) !important;
    }
  }

  &.course-stat--rp-hit-ration {
    .CircularProgressbar-path {
      stroke: var(--success-color) !important;
    }
  }
}

.course-card-stat--label {
  font-weight: 600;
  color: #666;
}

.mce-ico {
  width: 14px !important;
  height: 14px !important;
  color: #666 !important;
}

.mce-menu-item {
  color: #666 !important;
}

.mce-menu-item.mce-active.mce-menu-item-normal {
  background: #bbb !important;
}

.mce-btn.mce-active,
.mce-btn.mce-active:hover,
.mce-btn.mce-active:focus,
.mce-btn.mce-active:active {
  background: #bbb !important;
}

.cd-tour-wrapper {
  left: 230px !important;
  top: 5px !important;
}

.popover-help-video {
  max-width: 360px;
  width: 360px;
  border-radius: 5px;
  border-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  padding: 1.5em;
  font-family: "Inter";

  h2 {
    font-size: 20pt;
    color: #666;
    font-weight: 600;
  }

  p {
    font-size: 13pt;
    color: #666;
  }

  .arrow {
    background: #fff;
  }

  .arrow::before {
    border-right-color: #fff !important;
  }

  .change-step-btn {
    cursor: pointer;
    font-weight: 600;
    background: none;
    outline: none;
    border: none;
    color: #666;
    font-size: 1.2rem;
  }

  .change-step-btn:disabled {
    color: #999;
    cursor: default;
  }

  .steps-amount {
    padding-top: 0.1em;
    font-size: 1rem;
    color: #999;
  }

  .close-help-video-btn {
    background: none;
    outline: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    top: -10px;
    right: -10px;
    width: 32px;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    color: #999;
    cursor: pointer;
  }
}

.help-video-trigger-btn {
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  margin-top: 0.4rem;
  color: #999;
}

.course-locked {
  position: relative;

  &:before {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background: $secondary + dd;
    z-index: 2;
    border-radius: 5px;
    font-size: 2rem;
    color: white;
    @extend %flex-all-center;
    content: "\F33E";
    @extend %material-icons;
    padding: 1rem;
  }
  &:hover:before {
    content: "Complete as etapas anteriores da trilha para liberar esse treinamento";
    font-family: "Inter";
    font-size: 1rem;
    text-align: center;
  }
}

.header--course-main-details {
  .course-main-details--sub-header {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #999;
    margin-bottom: 0.5rem;
    display: block;
    font-weight: 600;
  }

  .course-main-details--title {
    color: #333;
  }

  .course-main-details--footer {
    font-size: 0.8rem;
    color: #666;
  }

  .course-details-list {
    color: #666;
    font-size: 1.1rem;

    &.course-details-list--sm {
      font-size: 1rem;
    }

    i {
      width: 2rem;
      display: inline-block;
      color: #ff6347;
    }

    li {
      margin: 1rem 0;
    }
  }
}

.shakeError {
  animation: shake 0.1s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
